/**
 *  This file has been extracted from react-router-dom and slightly modified.
 *
 *  Gatsby v5 should come with it bundled, so eventually we can just remove this
 */
import { useCallback, useMemo, useRef } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

type ParamKeyValuePair = [string, string];

function createSearchParams(init: URLSearchParamsInit = ""): URLSearchParams {
  return new URLSearchParams(
    typeof init === "string" ||
    Array.isArray(init) ||
    init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          let value = init[key];
          return memo.concat(
            Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]],
          );
        }, [] as ParamKeyValuePair[]),
  );
}

export type URLSearchParamsInit =
  | string
  | Record<string, string | string[]>
  | URLSearchParams;

export type SetURLSearchParams = (
  nextInit?:
    | URLSearchParamsInit
    | ((prev: URLSearchParams) => URLSearchParamsInit),
) => void;

function getSearchParamsForLocation(
  locationSearch: string,
  defaultSearchParams: URLSearchParams | null,
) {
  let searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    // Use `defaultSearchParams.forEach(...)` here instead of iterating of
    // `defaultSearchParams.keys()` to work-around a bug in Firefox related to
    // web extensions. Relevant Bugzilla tickets:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}

/**
 * useSearchParams is a hook that allows us to read and write the search params in the URL
 * It was extracted from react-router, since I believe that gatsby will come bundled with that in v5
 */
export function useSearchParamsReactRouterCompatible(
  defaultInit?: URLSearchParamsInit,
): [URLSearchParams, SetURLSearchParams] {
  let defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  let hasSetSearchParamsRef = useRef(false);

  const location = useLocation();
  const searchParams = useMemo(
    () =>
      // Only merge in the defaults if we haven't yet called setSearchParams.
      // Once we call that we want those to take precedence, otherwise you can't
      // remove a param with setSearchParams({}) if it has an initial value
      getSearchParamsForLocation(
        location.search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current,
      ),
    [location.search],
  );

  const setSearchParams = useCallback<SetURLSearchParams>(
    (nextInit) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === "function" ? nextInit(searchParams) : nextInit,
      );
      hasSetSearchParamsRef.current = true;
      navigate("?" + newSearchParams, { replace: true });
    },
    [navigate, searchParams],
  );

  return [searchParams, setSearchParams];
}
