import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Testimonial = ({ t }) => {
  return (
    <div className="flex-none py-6 px-3 first:pl-6 last:pr-6">
      <div className="flex flex-col items-center justify-center gap-3 rounded-lg p-4 border border-gray-200 text-xs text-left h-80">
        <GatsbyImage
          alt="Testimonial Image"
          className="w-56 h-28 lg:w-64 lg:h-32 rounded-md"
          image={getImage(t.Photo.localFile)}
        />
        <p className="w-56 text-left italic">"{t.Quote}"</p>
        <p className="w-full text-left py-2">{t.Author}</p>
      </div>
    </div>
  );
};

const query = graphql`
  query {
    allStrapiTestimonials {
      edges {
        node {
          id
          Quote
          Author
          Photo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  }
`;

const TestimonialsHorizontalScroll = () => {
  const { allStrapiTestimonials } = useStaticQuery(query);
  const testimonials = allStrapiTestimonials.edges.map((x) => x.node);
  return (
    <div className="relative rounded-xl overflow-auto">
      <div className=" mx-auto shadow-xl min-w-0">
        <div className="overflow-x-scroll flex">
          {testimonials.map((t, index) => (
            <Testimonial key={index} t={t} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsHorizontalScroll;
