import { useCallback, useMemo } from "react";
import { useSearchParamsReactRouterCompatible } from "./useSearchParamsReactRouterCompatible";

type SearchParams = Record<string, string>;
type SetSearchParams = (newValues?: Record<string, string | null>) => void;

/**
 * useSearchParams pulls out url search params into an object which makes it easy to use
 * and also allows for setting new values without overwriting the old ones.
 */
export function useSearchParams(): [SearchParams, SetSearchParams] {
  const [sp, setReachSp] = useSearchParamsReactRouterCompatible();
  const spValues = useMemo<SearchParams>(() => {
    const values: Record<string, string> = {};
    sp.forEach((value, key) => {
      values[key] = value;
    });
    return values;
  }, [sp]);

  const setSp = useCallback<SetSearchParams>(
    (newValues) => {
      setReachSp((prevSp: URLSearchParams) => {
        const clone = new URLSearchParams(prevSp.toString());
        for (const [key, value] of Object.entries(newValues)) {
          if (value) {
            clone.set(key, value);
          } else {
            // if falsy values are passed, just remove that from the url search
            // params entirely so an API call like this will unset city, and set state.
            // setSearchParams({ city: null })
            clone.delete(key);
          }
        }

        return clone;
      });
    },
    [setReachSp],
  );

  return [spValues, setSp];
}
