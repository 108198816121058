import React, { useCallback, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Layout, FAQ } from "../components";
import TestimonialsHorizontalScroll from "../components/TestimonialsHorizontalScroll";
import { useSearchParams } from "../components/useSearchParams";
import { proposalSummary, proposalSummaryResponse } from "../common/api";
import { Loading } from "../components/Loading";

type PricingSectionProps = {
  solarRatio: string;
  monthlyFee: string;
  systemSize: string;
};
const PricingSection = ({
  solarRatio,
  monthlyFee,
  systemSize,
}: PricingSectionProps) => {
  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6 font-semibold leading-6 text-gray-900">
          <p className="text-xl">{solarRatio}%</p>
          <p className="text-sm">Consumo anual cubierto por energía solar</p>
        </div>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6 font-semibold leading-6 text-gray-900">
          <p className="text-xl">{systemSize} kW</p>
          <p className="text-sm">Tamaño del sistema</p>
        </div>
        {/* Hiding this as recent price changes have hurt conversion
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6 font-semibold leading-6 text-gray-900">
          <p className="text-xl">${parseFloat(monthlyFee).toFixed(0)}</p>
          <p className="text-sm">Mensualidad</p>
        </div> */}
      </div>
    </div>
  );
};

const PreparaTuLlamada = ({ data }) => {
  const billIcon = getImage(data.billIcon.childImageSharp);
  const computerWithGraphsIcon = getImage(
    data.computerWithGraphsIcon.childImageSharp,
  );
  const piggyBankIcon = getImage(data.piggyBankIcon.childImageSharp);
  const docWithPencil = getImage(data.docWithPencil.childImageSharp);

  const whatToExpectElements = [
    {
      description:
        "Te explicaré cómo leer tu recibo de luz y tu situación energética actual y futura.",
      icon: billIcon,
      alt: "Bill",
    },
    {
      description:
        "Conocerás nuestros productos y elegiremos el sistema perfecto para ti.",
      icon: computerWithGraphsIcon,
      alt: "Computer with graphs",
    },

    {
      description:
        "Te ayudaré a identificar descuentos y opciones más económicas.",
      icon: piggyBankIcon,
      alt: "Piggy bank",
    },
    {
      description:
        "Te guiaré a través de los documentos de contratación, asegurando un proceso sencillo.",
      icon: docWithPencil,
      alt: "Document with pencil",
    },
  ];

  const faqs = data.allStrapiFaqs.edges;
  const [{ uuid }, setSp] = useSearchParams();
  const [response, setResponse] = useState<proposalSummaryResponse | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const loadProposalSummary = useCallback(
    async (uuid: string) => {
      try {
        const { body } = await proposalSummary(uuid);
        setResponse(body);
      } catch (error) {
        // if something fails, we want to fail gracefully (show static content)
      }
      setLoading(false);
    },
    [setResponse],
  );

  useEffect(() => {
    loadProposalSummary(uuid);
  }, [loadProposalSummary, uuid]);

  const { leadName, monthlyFee, solarRatio, systemSize, ec } = response ?? {};

  const energyExperts = data.allStrapiAuthor.edges;
  const energyExpert = ec
    ? energyExperts.filter((ee) => ee.node.name == ec.name)[0]
    : null;
  const energyExpertImg =
    energyExpert &&
    getImage(energyExpert.node.picture.localFile.childImageSharp);

  if (loading) {
    return (
      <Loading className="h-screen flex items-center flex justify-center items-center" />
    );
  }

  return (
    <Layout hideNav={true} hideFooter={true}>
      <div className="bg-white">
        <div className="mx-auto px-8 py-16 lg:py-24 md:max-w-3xl lg:max-w-5xl">
          <div className="text-center space-y-10">
            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl">
              <span className="block">
                ¡Felicidades, ya estás más cerca de una vida de confort y
                ahorros!{" "}
              </span>
            </h1>
            {/* Energy Expert intro Start */}
            <div className="bg-gray-100 p-6 rounded-lg">
              <div className="space-y-2 text-left">
                <p>
                  Hola <span className="capitalize">{leadName}</span>,
                </p>
                <p>
                  {ec ? (
                    <>
                      ¡Mi nombre es{" "}
                      <span className="capitalize">{ec.name}</span> y estoy aquí
                      para guiarte hacia una vida de confort y ahorros con
                      energía solar!{" "}
                    </>
                  ) : (
                    <>
                      ¡Estoy aquí para guiarte hacia una vida de confort y
                      ahorros con energía solar!{" "}
                    </>
                  )}
                  Antes de nuestra videollamada, quiero asegurarme que estás
                  completamente entusiasmado[a] y preparado[a] con todo lo que
                  tenemos para ofrecerte.
                </p>
              </div>
              <StaticImage
                alt="Casa con paneles solares de fondo y familia al frente"
                height={450}
                src="../images/illustrations/house_and_family.png"
              />
            </div>
            {/* Energy Expert intro End */}

            {/* About Us Start */}
            <div className="space-y-8">
              <div className="w-full p-2 rounded-tl-lg rounded-tr-lg bg-[#f4c333]/[0.33]">
                <h1 className="text-3xl">¿Quiénes Somos?</h1>
              </div>
              <div className="space-y-6 text-left">
                <p>
                  Bright es líder en energía solar en México, con más de 6000
                  suscriptores y 32 millones de vatios instalados en toda la
                  república mexicana.
                </p>
                <p>
                  Con inversionistas de renombre como Leonardo Dicaprio, el
                  creador de Gmail, entre otros, hemos levantado un capital de
                  más de $100 millones de dólares, ofreciendo soluciones
                  personalizadas que se adaptan a tus necesidades.
                </p>
              </div>
            </div>
            <TestimonialsHorizontalScroll />
            {/* About Us End */}

            {/* What to expect Start */}
            <div className="bg-blue-500 p-6 rounded-lg text-white space-y-6 ">
              <h1 className="text-xl md:text-2xl font-bold">
                ¿Qué esperar en nuestra llamada?
              </h1>
              <p className="text-left md:text-center">
                Durante nuestra videollamada: Identificaremos juntos tus
                necesidades energéticas.
              </p>
              <ul className="md:list-none list-disc space-y-6 px-8 py-6">
                {whatToExpectElements.map((el, idx) => (
                  <li key={idx} className="space-y-2">
                    <span>{el.description}</span>
                    <div>
                      <div className="inline-flex items-center justify-center p-6 bg-white rounded-full">
                        <GatsbyImage
                          alt={el.alt}
                          className="w-14 h-14 lg:w-16 lg:h-16"
                          image={el.icon}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <p className="text-left md:text-center">
                En cada paso del camino, estaremos contigo para brindarte apoyo
                y orientación, asegurándonos de que te sientas confiado y
                emocionado con tu decisión de unirte a la familia Bright.
              </p>
            </div>
            {/* What to expect End */}

            {/* FAQs Start */}
            <div className="flex flex-col mx-auto justify-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl space-y-6">
              <h2 className="text-3xl font-bold text-center">
                Preguntas frecuentes
              </h2>
              <p>
                Aquí hay algunas preguntas que nuestros clientes suelen hacer
                durante nuestra videollamada:
              </p>
              <div className="mx-auto py-12 px-8 max-w-2xl flex-col space-y-6">
                {faqs.map((faq, index) => (
                  <FAQ key={index} faq={faq.node} />
                ))}
              </div>
            </div>
            {/* FAQs End */}

            {/* Pricing Start */}
            {response && (
              <div className="space-y-6">
                <h2 className="text-3xl font-bold text-center">
                  Precios y opciones más económicas
                </h2>
                <p>
                  Basándome en los consumos de tu recibo de luz, esta es la
                  propuesta que se acomoda a tus necesidades:
                </p>
                <PricingSection
                  solarRatio={solarRatio}
                  monthlyFee={monthlyFee}
                  systemSize={systemSize}
                />
              </div>
            )}
            {/* Pricing End */}
          </div>
        </div>
      </div>

      {/* Goodbye section start */}
      <div className="bg-gray-50 space-y-16 py-16">
        <div className="max-w-2xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500 sm:text-4xl">
            ¡Nos vemos pronto!
          </h2>
          <p className="mt-6 text-lg leading-6">
            Me emociona platicar contigo y explorar cómo Bright puede iluminar
            tu vida. Si tienes alguna pregunta adicional antes de nuestra
            llamada, no dudes en ponerte en contacto conmigo.
          </p>
        </div>

        {ec && (
          <div className="mx-auto px-8 md:max-w-3xl lg:max-w-5xl">
            <div className="lg:col-start-3 lg:row-end-1">
              <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                <div className="mt-6 border-t border-gray-900/5 px-6 py-6 leading-6 text-gray-900">
                  <div className="flex justify-center">
                    <div className="mr-4 flex-shrink-0 self-center">
                      <GatsbyImage
                        alt="energy expert"
                        className="w-28 h-28 lg:w-42 lg:h-42 rounded-full"
                        image={energyExpertImg}
                      />
                    </div>
                    <div className="space-y-4 md:text-md text-sm">
                      <h4 className="text-lg font-semibold">{ec.name}</h4>
                      <h4>Representante de Ventas</h4>
                      <h4>{ec.phone}</h4>
                      <h4>{ec.email}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Goodbye section end */}
    </Layout>
  );
};

export default PreparaTuLlamada;

export const query = graphql`
  fragment icons on File {
    childImageSharp {
      gatsbyImageData(height: 100)
    }
  }

  query {
    billIcon: file(relativePath: { eq: "illustrations/bill-icon.png" }) {
      ...icons
    }
    computerWithGraphsIcon: file(
      relativePath: { eq: "illustrations/computer_with_graph-icon.png" }
    ) {
      ...icons
    }
    piggyBankIcon: file(
      relativePath: { eq: "illustrations/piggy_bank-icon.png" }
    ) {
      ...icons
    }
    docWithPencil: file(
      relativePath: { eq: "illustrations/doc_with_pencil-icon.png" }
    ) {
      ...icons
    }
    allStrapiFaqs(
      filter: { faq_category: { title: { eq: "Recordatorio de Cita" } } }
    ) {
      edges {
        node {
          question
          answer
        }
      }
    }
    allStrapiAuthor {
      edges {
        node {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
